import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SIGNATURE_COLOR_LIST } from '../../../modules/memos/service/upload-memo.constants';
import { base64ToFile } from '../../../core/services/utils.service';
import { CanvasWhiteboardComponent } from 'ng2-canvas-whiteboard';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { AlertService } from '../../../core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { OtpModalComponent } from '../otp-modal/otp-modal.component';
import { TextSignatureComponent } from '../../../modules/profile/component/text-signature/text-signature.component';
import { ProfileService } from '../../../modules/profile/shared/profile.service';
import { buildFullName } from '../../utils/common.util';
import { Store } from '@ngxs/store';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CropperPosition,
  Dimensions,
  ImageCropperComponent,
} from 'ngx-image-cropper';
import { ImageProcessingService } from '../../service/image-processing.service';
import * as moment from 'moment';
import { featureFlag } from 'src/environments/environment';
export type ActionType = 'approve' | 'reject' | 'terminate';

declare let gtag: Function;

@HostListener('window:resign', ['$event'])
@Component({
  selector: 'app-select-sign-method-modal',
  templateUrl: './select-sign-method-modal.component.html',
  styleUrls: ['./select-sign-method-modal.component.scss'],
})
export class SelectSignMethodModalComponent implements OnChanges {
  /** Enable typing signature text */
  @Input() customTextSignature = false;
  @Input() enableUploadSignature = false;
  @Input() enableUsingStoredSignature = false;
  @Input() enableTextSignature = false;
  @Input() isSaved: boolean;
  @Input() otpEnable: boolean;
  @Input() isNeedSign: boolean;
  @Input() isNoneUser = false;
  @Input() themeList: ThemeList;
  @Input() header;

  @Input() signatureText: string;
  @Input() otp: string;
  @Output() otpChange = new EventEmitter<string>();

  @Input() signature: File;
  @Output() signatureChange = new EventEmitter<File>();

  @Input() signaturePreview: string;
  @Input() canSignNow = true;
  /** Set the signature thickness on canvas.
   * When set it the responsive thickness is not used
   */
  @Input() signatureThickness: number;
  @Output() confirm = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  @Input() numberOfSignature: number = null;
  @Input() countPage: number = null;

  @Input() signMethod: string = null;
  @Output() signMethodChange = new EventEmitter<string>();
  @Output() storedSignature = new EventEmitter<string>();

  @ViewChild('canvasWhiteboard', { static: false })
  canvasWhiteboard: CanvasWhiteboardComponent;
  @ViewChild('selectSignMethodModal', { static: false })
  selectSignMethodModal: ElementRef;
  @ViewChild('signSignature', { static: false })
  signSignature: ElementRef;
  @ViewChild('uploadUserSignature', { static: false })
  uploadUserSignature: ElementRef;

  @ViewChild('storageSignatureModal', { static: false })
  storageSignatureModal: ElementRef;

  @ViewChild(OtpModalComponent)
  otpModalComponent: OtpModalComponent;
  @ViewChild('confirmSuccess')
  confirmSuccess: ConfirmModalComponent;
  @ViewChild('imageCropper')
  imageCropperComponent: ImageCropperComponent;

  // Sign now
  signImageBlob: any = null;
  blobOutput: any = '';
  croppedImage: any = '';
  imageChangedEvent: any = '';
  errorMsg: any = '';
  fileInput: any = '';
  signatureColorList = SIGNATURE_COLOR_LIST;
  selectedColor = this.signatureColorList[0];
  showColorPicker = false;
  isSignNow = false;
  isUpload = false;
  actionType: ActionType;

  selectSignMethodModalRef: NgbModalRef;
  showPopupLine = false;
  isMobile = null;
  screenHight: number;
  checkPortrait = this.breakpointObserver.isMatched([
    '(orientation: portrait)',
  ]);

  checkMobileLandscape = null;

  cropper: CropperPosition = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  };

  profile = null;
  storageSignature = null;
  newFeatureSp1 = featureFlag.open_feature_sprint_1;

  constructor(
    private profileService: ProfileService,
    public modalService: NgbModal,
    private alert: AlertService,
    public translate: TranslateService,
    private store: Store,
    private themeService: ThemeService,
    private breakpointObserver: BreakpointObserver,
    private ngxSpinner: NgxSpinnerService,
    private imageProcessingService: ImageProcessingService,
  ) {
    const profile = this.store.selectSnapshot((state) => state.auth);
    this.storageSignature = profile.signature;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enableUsingStoredSignature) {
      this.enableUsingStoredSignature =
        changes.enableUsingStoredSignature.currentValue;
    }
  }

  /**
   * Calculate signature thickness from the width of canvas.
   * This calculation base on A4 paper (H.297mm x W.210mm).
   * Typically the signature canvas width is about 17.5% of paper (36.75 mm).
   * The common pen ballpoint is 0.5mm.
   * So, a signature thickness is about 1.58% (0.5 / 31.5) of the signature canvas width.
   * @param canvasWidth The width of canvas of signature
   * @returns The thickness of the signature
   */
  calcSignatureThickness(canvasWidth: number): number {
    return canvasWidth * (0.5 / 36.75);
  }

  // TODO - There is the unused parameter
  getSignatureThickness(canvasWidth: number): number {
    return this.signatureThickness || 10;
  }

  getScreenSize(event?): void {
    this.screenHight = window.innerWidth;
  }

  openOtpModal(): void {
    this.signMethodChange.emit(this.signMethod);
    this.modalService.dismissAll();
    this.otpModalComponent.openModal();
  }

  openStorageSignatureModal(center = true): void {
    this.selectSignMethodModalRef = this.modalService.open(
      this.storageSignatureModal,
      {
        backdrop: 'static',
        size: 'lg',
        centered: center,
      },
    );
  }

  openSelectSignMethodModal(center = true): void {
    this.selectSignMethodModalRef = this.modalService.open(
      this.selectSignMethodModal,
      {
        backdrop: 'static',
        size: 'lg',
        centered: center,
      },
    );
  }

  openSignModal(): void {
    this.onResize();
    if (this.selectSignMethodModalRef) {
      this.selectSignMethodModalRef.dismiss('Cross Click');
    }
    if (this.isMobile) {
      const signModal = this.modalService.open(this.signSignature, {
        backdrop: 'static',
        keyboard: false,
        size: 'lg',
        centered: true,
        windowClass: 'custom-modal-sign',
      });
      signModal.result.finally(() => (this.isSignNow = false));
    } else {
      const signModal = this.modalService.open(this.signSignature, {
        backdrop: 'static',
        keyboard: false,
        size: 'lg',
        centered: true,
      });
      signModal.result.finally(() => (this.isSignNow = false));
    }
  }

  openUploadUserSignature(): void {
    this.modalService.open(this.uploadUserSignature, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  onClearCanvas(): void {
    this.canvasWhiteboard.clearCanvas();
    this.OnDeleteSignNow();
  }

  onUndoCanvas(): void {
    this.canvasWhiteboard.undo();
    this.OnUndoSignNow();
  }

  onClickColorPicker(): void {
    this.showColorPicker = !this.showColorPicker;
    this.OnColorChangedSignNow();
  }

  onSaveCanvas(): void {
    this.canvasWhiteboard.saveLocal();
    this.OnClosePage();
  }

  onSelectedColor(color: string): void {
    this.selectedColor = color;
  }

  // GA  Approved_Sign_Now
  signNowType: string = 'Doc_Approved_Sign_Now';
  startTime: number = 0;
  endTime: number = 0;
  numberOfReset: number = 0;
  numberOfUndo: string = 'No';
  isColorChanged: string = 'No';
  isLineChanged: string = 'No';

  OnFocusField() {
    if (window.performance) {
      this.startTime = window.performance.now();
    }
  }

  OnDeleteSignNow() {
    this.numberOfReset += 1;
  }

  OnUndoSignNow() {
    this.numberOfUndo = 'Yes';
  }

  OnColorChangedSignNow() {
    this.isColorChanged = 'Yes';
  }

  OnLineChangedSignNow() {
    this.isLineChanged = 'Yes';
  }

  OnClosePage() {
    this.endTime = window.performance.now();

    gtag('event', this.signNowType, {
      Time_on_sign: this.endTime - this.startTime,
      Number_of_Reset: this.numberOfReset,
      Number_of_Undo: this.numberOfUndo,
      Is_color_changed: this.isColorChanged,
      Is_line_changed: this.isLineChanged,
    });
  }

  onSave(event): void {
    this.canvasWhiteboard.generateCanvasBlob((blob) => {
      this.blobOutput = blob;
      this.signImageBlob = blob;
      this.isSignNow = false;
    });
  }

  onSignNowClick(): void {
    this.onDocApprovedSign('Sign_Now');
    this.signMethod = 'sign_now';
    this.signatureThickness = 10;
    this.isSignNow = true;
    this.openSignModal();
  }

  onDocApprovedSign(type: string): void {
    // Gtag
    const DocApprovedSign = localStorage.getItem('Doc_Approved_Sign');
    if (DocApprovedSign) {
      const parseDocApprovedSign = JSON.parse(DocApprovedSign);
      const payloadLocalStorage = {
        ...parseDocApprovedSign,
        Type_Sign: type,
        Start_Time: moment().format(),
      };
      localStorage.setItem(
        'Doc_Approved_Sign',
        JSON.stringify(payloadLocalStorage),
      );
    } else {
      const payloadLocalStorage = {
        Type_Sign: type,
        Start_Time: moment().format(),
      };
      localStorage.setItem(
        'Doc_Approved_Sign',
        JSON.stringify(payloadLocalStorage),
      );
    }
    // End Gtag
  }

  onHandleSubmitSignStorage(): void {
    this.onDocApprovedSign('Stored');

    this.signMethod = 'stored';
    this.storedSignature.emit(this.storageSignature);
    if (this.newFeatureSp1) {
      this.submit();
    } else {
      this.otpEnable ? this.openOtpModal() : this.submit();
    }
  }

  onTextSignatureClick(): void {
    this.onDocApprovedSign('Typeing');

    this.signMethod = 'sign_by_typing';
    if (this.selectSignMethodModalRef) {
      this.selectSignMethodModalRef.dismiss();
    }
    const profile = this.store.selectSnapshot((state) => state.auth);
    const modal = this.modalService.open(TextSignatureComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modal.componentInstance.selectOnly = !this.customTextSignature;
    modal.componentInstance.fullName =
      this.signatureText ||
      buildFullName(profile.first_name, profile.last_name) ||
      '';
    const cancelClick = new EventEmitter();
    cancelClick.subscribe({
      next: () => this.reOpenSelectSignModal(),
    });
    const submitClick = new EventEmitter();
    submitClick.subscribe({
      next: (res) => modal.close(res),
    });
    modal.componentInstance.cancelClick = cancelClick;
    modal.componentInstance.signatureSaveClick = submitClick;
    modal.result
      .then((res) => {
        this.signatureChange.emit(res);
        if (!this.newFeatureSp1) {
          if (this.otpEnable) {
            this.openOtpModal();
            return;
          }
        }

        this.submit();
      })
      .catch(() => {});
  }

  imageCropped(output): void {
    this.croppedImage = output.base64;
    this.blobOutput = base64ToFile(
      output.base64,
      this.imageChangedEvent.target?.files[0]?.name,
    );
  }

  imageLoaded(): void {
    // show cropper
  }

  loadImageFailed(): void {
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.errorMsg = 'APPROVAL.ERROR-UNABLE-UPLOAD';
  }

  fileChangeEvent(file: any): void {
    this.errorMsg = '';
    this.imageChangedEvent = file;
    this.fileInput = file.target.files[0];
  }

  resetData(): void {
    this.isUpload = false;
    this.isSignNow = false;
    this.signImageBlob = null;
    this.blobOutput = '';
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.errorMsg = '';
    this.fileInput = '';
  }

  clearSignature(): void {
    this.alert
      .confirm(this.translate.instant('ALERT.Are you sure'), ' ')
      .then((res) => {
        if (res.value) {
          this.reOpenSelectSignModal();
        }
      });
  }

  changeComplete(event): void {
    this.selectedColor = event.color.hex;
  }

  saveSignature(): void {
    this.signature = new File([this.blobOutput], 'online-sign.png', {
      type: this.blobOutput.type,
    });
    this.signatureChange.emit(this.signature);
    if (!this.newFeatureSp1) {
      if (this.otpEnable) {
        this.openOtpModal();
        return;
      }
    }
    this.submit();
  }

  filePhotoChangeEvent(event: any): void {
    this.signMethod = 'upload_signature';
    this.isUpload = true;
    this.errorMsg = '';
    this.signImageBlob = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {};
    this.openSignModal();
    // Clear input file to avoid error when upload same file again
    event.target.value = '';
  }

  submit(): void {
    this.signMethodChange.emit(this.signMethod);
    this.confirm.emit();
    this.resetData();
  }

  close(): void {
    this.modalService.dismissAll();
    this.closeModal.emit();
    this.resetData();
  }

  reOpenSelectSignModal(): void {
    this.modalService.dismissAll();
    this.resetData();
    this.openSelectSignMethodModal(true);
  }

  verifyOTP(): void {
    if (this.otpModalComponent.invalidOTP) {
      this.alert.error('Input OTP');
      return;
    }

    this.otpChange.emit(this.otp);
    this.submit();
  }

  onEditSizeLine(): void {
    this.showPopupLine = !this.showPopupLine;
    this.OnLineChangedSignNow();
  }

  resizeLine(event): void {
    this.getSignatureThickness(this.signatureThickness);
  }

  resetSizeLine(): void {
    this.signatureThickness = 10;
  }

  onResize(event?): void {
    if (window.innerWidth < 576 && window.innerHeight > 500) {
      this.isMobile = true;
      this.checkPortrait = true;
      this.checkMobileLandscape = false;
    }
    if (window.innerHeight < 500) {
      this.checkMobileLandscape = true;
      this.isMobile = true;
      this.checkPortrait = false;
    }
  }

  closeTool(): void {
    this.showColorPicker = false;
    this.showPopupLine = false;
  }

  async onCropperReady(dimensions: Dimensions) {
    this.cropper =
      await this.imageProcessingService.getSignatureBoundary(
        this.imageCropperComponent.imageFile,
      );
  }
}
