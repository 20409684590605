import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoginSSo } from '../../../store/auth/auth.actions';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../services/spinner.service';
import { SSO_URL } from '../../http/api.constant';
import { featureFlag } from 'src/environments/environment';
import {
  ThemeList,
  ThemeService,
} from 'src/app/shared/service/theme.service';

@Component({
  selector: 'app-sso-callback',
  templateUrl: './sso-callback.component.html',
  styleUrls: ['./sso-callback.component.scss'],
})
export class SsoCallbackComponent implements OnInit {
  newFeature = featureFlag.close_feature;
  queryParam: any;
  localKey = 'sso-redirect-portal';
  loginError: boolean = false;
  loginErrorText: string = '';
  loginErrorDetail: string = '';
  language = 'en';
  themeList: ThemeList;
  isMobile;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'compact';

  externalLink = null;

  externalLinkOld = {
    faq: 'https://cpfito365.sharepoint.com/:u:/s/SAPERPProject/EZserTifNR9Kmz9FO_kBPowBDqImP_6Pu_DfIr8yZVvfLg?e=tbBXun',
    feedback: 'https://scrm.cpf.co.th/',
    rating: 'https://forms.office.com/r/8WcpueMG8r',
  };

  externalLinkNew = {
    // eslint-disable-next-line max-len
    faq: 'https://docs.google.com/presentation/d/1ybZh_c4hYrDII9K4Hr-DLGAjnsxFfl2G/edit?usp=sharing&ouid=117556539342367831444&rtpof=true&sd=true',
    feedback:
      ' https://docs.google.com/forms/d/e/1FAIpQLSd5T9uXjUHA4gXOAKr-2F1u2EHEmKGZxeTtQRDv5ZvnNf71Rw/viewform?usp=pp_url',
    // eslint-disable-next-line max-len
    help: 'https://docs.google.com/presentation/d/1rbYFQMpnXyslhtQYbQw_f1p2KrmETEMt/edit?usp=sharing&ouid=117556539342367831444&rtpof=true&sd=true',
    policy: ' https://www.cpfworldwide.com/th/privacynotice',
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private zone: NgZone,
    private alert: AlertService,
    public translate: TranslateService,
    private themeService: ThemeService,
    private spinner: SpinnerService,
  ) {
    this.language = this.translate.currentLang;
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
    this.externalLink = featureFlag.close_feature
      ? this.externalLinkNew
      : this.externalLinkOld;
  }

  ngOnInit(): void {
    this.changeLanguage(this.language);
    this.isMobile = this.themeService.isMobile();

    const { code, state } = this.route.snapshot.queryParams;
    const ssoState = this.ssoLocalStorage('get');

    if (!code) {
      this.handleNoCode(ssoState);
      return;
    }

    this.processLogin(code, state);
    this.checkResponsive();
  }

  onResize(event): void {
    const pageWidth = event.target.innerWidth;
    this.isMobile = pageWidth < 769;
    this.checkResponsive();
  }

  checkResponsive(): void {
    if (this.isMobile) {
      this.size = 'compact';
    } else {
      this.size = 'normal';
    }
  }

  changeLanguage(lang: string): void {
    this.language = lang;
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    }
    if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }

  private handleNoCode(ssoState: boolean | undefined): void {
    if (ssoState) {
      this.ssoLocalStorage('remove');
      this.loginNavigate();
    } else {
      this.ssoLocalStorage('set', true);
      window.location.href = `${SSO_URL.cpfconnect.url}/oauth/authorize?response_type=${SSO_URL.cpfconnect.responseType}&client_id=${SSO_URL.cpfconnect.clientId}&redirect_uri=${SSO_URL.cpfconnect.redirectUri}&scope=${SSO_URL.cpfconnect.scope}&state=${SSO_URL.cpfconnect.state}`;
    }
  }

  loginNavigate(): void {
    this.router.navigate(['/login']);
  }

  private processLogin(code: string, state: string): void {
    this.store
      .dispatch(
        new LoginSSo({
          code: code,
          type: state || 'eko-cpf',
        }),
      )
      .subscribe(
        (response) => {
          this.ssoLocalStorage('remove');
          this.handleLoginSuccess(response);
        },
        (error) => {
          this.loginError = true;

          this.loginErrorText = this.translate.instant(
            'LOG.CAN NOT LOGIN',
          );
          this.loginErrorDetail = this.translate.instant(
            'LOGIN.BACK-LOGIN',
          );

          const message = error?.error?.message || '';

          // if (
          //   message === 'User does not exist' ||
          //   message === 'User is disabled'
          // ) {
          //   this.loginErrorText = this.translate.instant(
          //     'AUTH.User access denied title',
          //   );
          //   this.loginErrorDetail = this.translate.instant(
          //     'AUTH.User access denied detail',
          //   );
          // }
          // this.router.navigate(['/login'], {
          //   queryParams: { type: 'cloud-service', message: message },
          // });
          this.router.navigate(['/login'], {
            queryParams: { type: 'cloud-service', message: message },
          });

          // return this.handleLoginError(this.loginErrorText);
          return;
        },
      );
  }

  private handleLoginSuccess(response: any): void {
    if (this.isAccountActivationRequired(response)) {
      this.navigateAndReload('/activate-account');
      return;
    }

    if (this.isApprovalPending()) {
      const { approval, lang } = this.queryParam;
      this.router.navigateByUrl(`/approval/${approval}?lang=${lang}`);
      return;
    }

    if (this.queryParam?.url) {
      this.router.navigateByUrl(`${this.queryParam?.url}`);
      return;
    }

    this.handleRoleBasedNavigation(response.auth.role);
  }

  private isAccountActivationRequired(response: any): boolean {
    return (
      !response.auth.is_set_password ||
      !response.auth.is_pass_t_c ||
      response.auth.is_password_expired ||
      (!response.auth.otp_type &&
        ['General', 'Broadcast'].includes(response.auth.role))
    );
  }

  private isApprovalPending(): boolean {
    return Boolean(this.queryParam?.approval);
  }

  private handleRoleBasedNavigation(role: string): void {
    const destination =
      role === 'General' || role === 'Broadcast' ? '/memos' : '/users';
    this.zone.run(() => this.router.navigate([destination]));
  }

  private handleLoginError(message: any): void {
    const errorMessage =
      message || this.translate.instant('ERROR.CONTACT-DEV');
    this.alert.error(errorMessage);
    this.spinner.hide();
  }

  private navigateAndReload(route: string): void {
    this.zone.run(() => {
      this.router.navigate([route]).then(() => location.reload());
    });
  }

  private ssoLocalStorage(
    type: 'get' | 'set' | 'remove',
    value?: boolean,
  ): boolean | undefined {
    if (type === 'set') {
      localStorage.setItem(this.localKey, JSON.stringify(value));
      return value;
    } else if (type === 'remove') {
      localStorage.removeItem(this.localKey);
      return undefined;
    } else {
      const data = localStorage.getItem(this.localKey);
      return data ? (JSON.parse(data) as boolean) : undefined;
    }
  }
}
