<div class="row"
    (window:resize)="onResize($event)">
    <div class="col-lg-6 col-md-12 col-12 px-0 bg-image"
        [ngStyle]="{'background-image': 'url('+(themeList?.bg_login_image| secure | async)+ ')'}">
    </div>
    <div class="col-lg-6 col-md-12 col-12 px-0">
        <div class="login-frame bg overflow-y-auto"
            *ngIf="currentTime"
            [ngStyle]="{'background': '#f6f6f6',
            'color': themeList?.text_login_color}">
            <div class="translate"
                [style.margin-top.rem]="2">
                <div class="box-translate p-1 pointer">
                    <div (click)="changeLanguage('th')"
                        *ngIf="this.language === 'en'">
                        <svg-icon src="assets/images/icons/usa.svg"
                            [svgStyle]="{ 'height.px': 30, 'width.px': 30}">
                        </svg-icon>
                        <span class="pl-2">EN</span>
                    </div>
                    <div (click)="changeLanguage('en')"
                        *ngIf="this.language === 'th'">
                        <svg-icon src="assets/images/icons/thai.svg"
                            [svgStyle]="{ 'height.px': 30, 'width.px': 30}">
                        </svg-icon>
                        <span class="pl-2">TH</span>
                    </div>
                </div>
            </div>
            <div class="mb-0 text-left p-3 px-md-5 pt-md-0 px-5">
                <h4 class="title-terms mt-3">
                    {{"RESET-PASS.Terms and Conditions"|translate}}
                </h4>
                <div class="box-content mt-3 mt-md-4 col-12 px-0">
                    <div class="content-terms"
                        [innerHTML]="translate.currentLang === 'en'? termsDetail.T_AND_C_EN: termsDetail.T_AND_C_TH">
                    </div>
                </div>
            </div>
            <div class="login-box">


            </div>
            <div class="version"
                [ngStyle]="{'color': '#344054'}">
                <div class="mt-3 position-support pl-3">
                    <div class="pointer menu"
                        (click)="redirectToExternalUrl('faq')">
                        <svg-icon src="assets/svg/login/icon-faq.svg"
                            [stretch]="true"
                            [svgStyle]="{'fill': '#344054', 'width.px': 18, 'height.px': 16}"
                            class="mr-2">
                        </svg-icon> {{"LOGIN.FAQ"|translate}}
                    </div>
                    <div class="ml-3 pointer menu"
                        (click)="redirectToExternalUrl('feedback')">
                        <svg-icon src="assets/svg/login/icon-chat.svg"
                            [stretch]="true"
                            [svgStyle]="{'fill': '#344054', 'width.px': 18, 'height.px': 18}"
                            class="mr-2">
                        </svg-icon> {{"LOGIN.Feedback"|translate}}
                    </div>
                    <div class="ml-3 pointer"
                        (click)="redirectToExternalUrl('help')">
                        <svg-icon src="assets/svg/login/icon-help.svg"
                            [svgStyle]="{'fill': '#344054', 'width.px': 18, 'height.px': 18}"
                            [stretch]="true"
                            class="mr-2">
                        </svg-icon> {{"LOGIN.Help tips"|translate}}
                    </div>
                </div>
                <div class="mx-0 my-2">v.{{version}}</div>
                <div class="pl-3"> © 2022 Power by AXONS. All Rights Reserved.</div>

            </div>
            <div class="mx-auto"
                [style.margin-bottom.rem]="2">
                <div class="row">
                    <div (click)="redirectToExternalUrl('policy')"
                        class="pointer col-6 policy text-left pl-0">{{"LOGIN.Privacy Policy"|translate}}</div>
                    <div class="pointer col-6 policy text-right pr-0">{{"LOGIN.Terms and Conditions"|translate}}</div>
                </div>

            </div>
            <div class="text-right position-relative">
                <!--        <div class="position-bg-logo">-->
                <!--          <svg-icon src="assets/images/bg-logo.svg"-->
                <!--              [stretch]="true">-->
                <!--          </svg-icon>-->
                <!--        </div>-->
                <!--      </div>-->
            </div>
        </div>
    </div>