import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from '../../../shared/service/api.constant';
import {
  CommentList,
  ComponentData,
  ComponentSessionConfig,
  DownloadInfo,
  DownloadWithAttachmentsPayload,
  MemoDetail,
  MemoListDetail,
} from '../model/memo.model';
import { UploadMemoPayload } from '../model/template.model';
import { customAlphabet } from 'nanoid';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { DrawingService } from '../components/upload-memos/drawing/drawing.service';
import { Member } from '../../loa/shared/loa.model';
import { MarkerIdentity } from '../components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';
import { EMAIL_PATTERN } from './upload-memo.constants';
import { NgbThaiDateParserFormatter } from './NgDateParser';
import { featureFlag } from 'src/environments/environment';

const nanoid = customAlphabet(
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
);

@Injectable({
  providedIn: 'root',
})
export class MemoService {
  headers: HttpHeaders;
  historyText: string;
  loaList: any;
  private setLoa = new Subject();
  data = this.setLoa.asObservable();

  private setReset = new Subject();
  onSetResetImg = this.setReset.asObservable();

  private setResetData = new Subject();
  onSetResetData = this.setResetData.asObservable();

  changeTable: any;
  private setTable = new Subject();
  tableData = this.setTable.asObservable();

  requestPo: Observable<any>;

  checkQueryChange = new Subject<any>();
  checkQuery = this.checkQueryChange.asObservable();

  checkCreteFolder = new Subject<any>();
  checkFolderChange = this.checkCreteFolder.asObservable();

  private checkResetSettings = new Subject();
  isResetSettings = this.checkResetSettings.asObservable();

  resetValid = new Subject();
  resetValid$ = this.resetValid.asObservable();

  resetNumberSubject = new Subject();
  resetNumber$ = this.resetNumberSubject.asObservable();

  private approvedSubject = new Subject();
  approved$ = this.approvedSubject.asObservable();

  private sidebar = new Subject();
  sidebar$ = this.sidebar.asObservable();

  private noneUserName = new Subject();
  noneUserName$ = this.noneUserName.asObservable();

  private noneUserEmail = new Subject();
  noneUserEmail$ = this.noneUserEmail.asObservable();

  private signatureReq = new Subject();
  signatureReq$ = this.signatureReq.asObservable();

  private signatureUpload = new Subject();
  signatureUpload$ = this.signatureUpload.asObservable();

  positionKeys = [
    'positions',
    'approved_date_positions',
    'comment_positions',
    'stamp_positions',
    'drawing_positions',
    'form_attachments',
    'form_text_positions',
    'form_checkbox_positions',
  ];

  emailPattern = EMAIL_PATTERN;
  newFeatureSp1 = featureFlag.open_feature_sprint_1;

  constructor(
    private http: ApiService,
    private translate: TranslateService,
    private drawingService: DrawingService,
    private httpClient: HttpClient,
    private dateFormat: NgbThaiDateParserFormatter,
  ) {}

  getDepartmentList(data?: { [type: string]: string }) {
    return this.http.get(API_URL.departments, data);
  }

  previewMemoPDF(data: { [type: string]: string }) {
    return this.http.pdfPost(API_URL.memo_preview, data);
  }

  // My task
  getMyTaskList(params?: { [type: string]: string }) {
    return this.http.get(API_URL.my_task, params);
  }

  getMyTaskBadge(params?: { [type: string]: string }) {
    return this.http.get(API_URL.my_task + 'badge/', params);
  }

  /**
   * Get the unique sessions property from `ComponentData`.
   * If all components is undefined a session then it return empty array.
   */
  getInputComponentSessions(
    inputComponents: ComponentData[],
    ignoreUndefinedGroup = true,
  ): ComponentSessionConfig[] {
    const sessionNames: string[] = [];
    const sessions: ComponentSessionConfig[] = [];
    const retrieveSessionName = (
      session: string | ComponentSessionConfig,
    ) => {
      if (typeof session === 'object') {
        return session.sessionName;
      }
      return session;
    };
    inputComponents.forEach((component) => {
      const sessionName = retrieveSessionName(component.session);
      if (ignoreUndefinedGroup && !sessionName) {
        return;
      }
      const isDuplicated = sessionNames.includes(sessionName);
      if (!isDuplicated && component.componentName != null) {
        sessionNames.push(sessionName);
        sessions.push(this.rewrapSessionConfig(component.session));
      }
    });
    return sessions;
  }

  // RealMemo
  getGeneralMemoList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.general_memo,
      params,
    );
  }

  // Template
  getTemplateList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.custom_template,
      params,
    );
  }

  // Template
  getFolderById(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.folder_detail,
      params,
    );
  }

  getMemoListCSV(data) {
    return this.http.getBlob(API_URL.general_memo + 'excel/', data);
  }

  getDownloadInfo(params?: {
    [type: string]: string;
  }): Observable<DownloadInfo[]> {
    return this.http.get(
      API_URL.general_memo + 'download-info/',
      params,
    );
  }

  getMemoFiles(data: DownloadWithAttachmentsPayload) {
    return this.http.postBlobResponse(
      API_URL.general_memo + 'download-multiple-pdf/',
      data,
    );
  }

  getMemoReferenceById(id) {
    return this.http.get(API_URL.memos + id + '/references/');
  }

  updateMemoReferenceById(id, data) {
    return this.http.post(
      API_URL.memos + id + '/update-references/',
      data,
    );
  }

  // Action
  createMemo(data: UploadMemoPayload): Observable<MemoDetail> {
    return this.http.post(API_URL.memos, data);
  }

  updateMemo(id, data): Observable<MemoDetail> {
    return this.http.patch(API_URL.memos + id + '/', data);
  }

  updateAnnouncement(id, data) {
    return this.http.post(
      API_URL.memos + id + '/announcement/',
      data,
    );
  }

  publishMemo(id, data?) {
    return this.http.post(
      API_URL.general_memo + id + '/publish/',
      data,
    );
  }

  extendMemo(id, data) {
    return this.http.post(
      API_URL.general_memo + id + '/extend/',
      data,
    );
  }

  changeExpiryDate(id, data) {
    return this.http.post(
      API_URL.memos + id + '/set-custom-expire-date/',
      data,
    );
  }

  extendBroadcast(id, data) {
    return this.http.post(
      `/api/memos/broadcast/` + id + '/extend/',
      data,
    );
  }

  reviseMemo(id) {
    return this.http.post(API_URL.memos + id + '/revise/', {});
  }

  recallMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/recall/', data);
  }

  approveMemo(id, data?) {
    return this.http.post(
      API_URL.general_memo + id + '/approve/',
      data,
    );
  }

  approveMemoNDA(data?) {
    return this.http.post(
      API_URL.general_memo + 'nda_approve/',
      data,
    );
  }

  checkIsActiveNDA(data?) {
    return this.http.post(
      API_URL.general_memo + 'check_active_nda/',
      data,
    );
  }
  rejectMemo(id, data?) {
    return this.http.post(
      API_URL.general_memo + id + '/reject/',
      data,
    );
  }

  terminateMemo(id, data?) {
    return this.http.post(
      API_URL.general_memo + id + '/terminate/',
      data,
    );
  }

  resetDueDateMemo(id, data?) {
    return this.http.post(
      API_URL.memos + id + '/set-custom-expire-date/',
      data,
    );
  }

  trashMemo(id, data?) {
    return this.http.post(
      API_URL.general_memo + id + '/trash/',
      data,
    );
  }

  multiTrashMemo(data?) {
    return this.http.post(API_URL.general_memo + 'bulk-trash/', data);
  }

  returnMemo(data?) {
    return this.http.post(
      API_URL.general_memo + 'bulk-untrash/',
      data,
    );
  }

  permanentlyDelete(id, data?) {
    return this.http.post(
      API_URL.general_memo + id + '/permanent-delete/',
      data,
    );
  }

  bulkPermanentlyDelete(data?) {
    return this.http.post(
      API_URL.general_memo + 'bulk-permanent-delete/',
      data,
    );
  }

  downloadMemo(id, data?) {
    return this.http.post(
      API_URL.memos + id + '/download-pdf/',
      data,
    );
  }

  getMemoDetail(id: number): Observable<MemoDetail> {
    return this.http.get(API_URL.memos + id + '/');
  }

  getMemoHistory(params?: { [type: string]: string }) {
    return this.http.get(API_URL.memos_history, params);
  }

  getHistoryLogCSV(data) {
    return this.http.getBlob(API_URL.memos_history + 'excel/', data);
  }

  deleteMemo(id) {
    return this.http.delete(API_URL.memos + id + '/');
  }

  deleteTemplate(id) {
    return this.http.post(API_URL.memos_template, id);
  }

  // Upload Blob

  uploadBlob(id, data, type) {
    return this.http.patch(API_URL[type] + id + '/', data);
  }

  removeBlob(data) {
    return this.http.post(API_URL.remove_memo_blob, data);
  }

  // Comment

  getCommentList(params): Observable<CommentList[]> {
    return this.http.get(API_URL.memo_comment, params);
  }

  createNewComment(data): Observable<CommentList> {
    return this.http.post(API_URL.memo_comment, data);
  }

  updateMemoRead(data) {
    return this.http.post(API_URL.memo_read, data);
  }

  deleteComment(id) {
    return this.http.delete(API_URL.memo_comment + id + '/');
  }

  // Attachment
  getMemoAttachment(params?: { [type: string]: string }) {
    return this.http.get(API_URL.memo_attachment, params);
  }

  removeMemoAttachment(id) {
    return this.http.delete(API_URL.memo_attachment + id + '/');
  }

  updateMemoAttchment(id, data) {
    return this.http.patch(API_URL.memo_attachment + id + '/', data);
  }

  uploadMemoAttachment(data) {
    return this.http.post(API_URL.memo_bulk_attachment, data);
  }

  downloadMemoAttachment(id, data?) {
    return this.http.post(
      API_URL.memo_attachment + id + '/download/',
      data,
    );
  }

  // Verify Duplicated Memo Number
  verifyMemoNumber(params) {
    return this.http.get(API_URL.memo_number_verify, params);
  }

  printFile(url) {
    return this.http.printFile(url);
  }

  getMemoTypes() {
    const params = { type: 'memo_type' };
    return this.http.get(API_URL.dropdown, params);
  }

  setLoadLoaList(department) {
    this.loaList = department;
    this.setLoa.next(this.loaList);
  }

  setResetImg(markerIdentity) {
    this.setReset.next(markerIdentity);
  }

  getMemoRevised(params) {
    return this.http.get(API_URL.memo_revised, params);
  }

  // acknowledgement
  getAcknowledge(params) {
    return this.http.get('/api/acknowledges/', params);
  }

  getAcknowledgeCSV(data) {
    return this.http.getBlob('/api/acknowledges/excel/', data);
  }

  // download file
  createDownloadFile(data: any, filename: string): void {
    // for IE10+
    const blob = new Blob([data], { type: data.type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }

  rewrapSessionConfig(
    session: string | ComponentSessionConfig,
  ): ComponentSessionConfig {
    if (typeof session === 'string') {
      return { sessionName: session };
    }
    return session;
  }

  updateMemoDetail(id, data) {
    return this.http.patch(API_URL.memo_detail + id + '/', data);
  }

  resentEmail(id, data?) {
    return this.http.post(
      '/api/memos/general/' + id + '/resend-noti/',
      data,
    );
  }

  getChoices(id) {
    return this.http.get(
      '/api/broadcast-memos/' + id + '/respond-choices/',
    );
  }

  getCompanyWidget(id: number) {
    return this.http.get('/api/ad-info/?person_id=' + id);
  }

  convertURLToFile(url: string, fileName: string): Promise<File> {
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .toPromise()
      .then((blob: any) => {
        const file = new File([blob], fileName);
        return file;
      });
  }

  pdfFileLocal() {
    return this.httpClient.get('assets/pdf/pdf.pdf', {
      responseType: 'blob',
    });
  }

  getFolder() {
    return this.http.get(API_URL.folder);
  }

  createFolder(data) {
    return this.http.post(API_URL.folder, data);
  }

  moveFolder(data) {
    return this.http.post(API_URL.move_folder, data);
  }

  editFolder(id, data) {
    return this.http.patch(`/api/memo-folder/${id}/`, data);
  }

  deleteFolder(id, data?) {
    return this.http.delete(`/api/memo-folder/${id}/`);
  }

  deleteMemoFormFolder(data) {
    return this.http.post(API_URL.remove_folder, data);
  }

  findMemberApproved(document, previewSignature) {
    const memberIsApproved = [];
    document.table_data.levels.forEach((level) => {
      level.members.forEach((member) => {
        if (member.date_of_approve) {
          memberIsApproved.push(member);
        }
      });
    });
    const memoType = document.upload;
    memberIsApproved.forEach((member) => {
      memoType?.signature_position.forEach((value) => {
        const fe_mem_id = value?.fe_mem_id;
        value.can_edit_widget = false;
        if (fe_mem_id != null && member?.fe_mem_id == fe_mem_id) {
          // delete value.positions;
          // delete value.drawing_positions;
          // delete value.stamp_positions;
          // delete value.approved_date_positions;
          // delete value.form_text_positions;
          // delete value.form_checkbox_positions;
          value.can_edit_widget = true;
          (value?.form_attachments ?? []).forEach((v) => {
            v.isApproved = true;
          });
          previewSignature.push(value);
        }
      });
    });
    return previewSignature;
  }

  checkSomeWidget(marker) {
    const haveMarker = [];
    marker.forEach((marker) => {
      this.positionKeys.forEach((key) => {
        if (marker[key]?.length > 0) {
          haveMarker.push(marker[key]);
        }
      });
    });
    const checkMarker = haveMarker.length > 0;
    return checkMarker;
  }

  convertWidget(previewSignature) {
    const marker = previewSignature;
    marker.forEach((marker) => {
      this.positionKeys.forEach((key) => {
        if (marker[key]?.length > 0) {
          marker[key]?.forEach((item) => {
            item.enableSetting = false;
            const calSize = 0;
            // if (item.optionalType === 'form_text_positions') {
            //   const defSize = 16 - item.fontSizePx;
            //   calSize = defSize ? defSize * 0.0095 : 0.0095;
            //   item.Y = item.Y - (0.25 + calSize);
            item.data_reset = item.data;
            // }
          });
        }
      });
    });
    return marker;
  }

  removeWidgetBg(previewSignature, totalPage) {
    const marker = previewSignature;
    marker.forEach((mark) => {
      this.positionKeys.forEach((key) => {
        if (mark[key]?.length > 0) {
          mark[key] = mark[key]?.filter((item) => {
            if (item.X > 0 && item.Y > 0 && item.page <= totalPage) {
              return item;
            }
          });
        }
      });
    });
    return marker;
  }

  convertWidgetForPreview(previewSignature) {
    const marker = previewSignature;
    marker.forEach((marker) => {
      this.positionKeys.forEach((key) => {
        if (marker[key]?.length > 0) {
          marker[key]?.forEach((item) => {
            item.enableSetting = false;
            const calSize = 0;
            // if (item.optionalType === 'form_text_positions') {
            //   const defSize = 16 - item.fontSizePx;
            //   calSize = defSize ? defSize * 0.0095 : 0.095;
            //   item.Y = item.Y + (0.25 + calSize);
            // }
          });
        }
      });
    });
    return marker;
  }

  isWidgetRequired(previewSignature): boolean {
    const marker = previewSignature;
    const required = [];
    let isRequired = false;
    marker.forEach((marker) => {
      this.positionKeys.forEach((key) => {
        if (marker[key]?.length > 0) {
          marker[key]?.forEach((item) => {
            if (
              item.req !== undefined ||
              item.required !== undefined
            ) {
              required.push(item);
            }
          });
        }
      });
    });
    isRequired = required.length > 0;
    return isRequired;
  }

  errorMessage(previewSignature, memo, currentMember?) {
    const findAttach = [];
    const findStamp = [];
    const findSignature = [];
    const findError = {};
    const findCheckbox = [];
    const findRadio = [];
    let errorMessage = {};
    const currentMemberInfo: Partial<Member> =
      currentMember.currentMemberInfo;
    previewSignature?.forEach((v) => {
      const fe_mem_id = v.fe_mem_id;
      if (
        (fe_mem_id != null &&
          currentMemberInfo?.fe_mem_id == fe_mem_id) ||
        (fe_mem_id == null && memo.current_level === v.level)
      ) {
        if (this.newFeatureSp1) {
          if (v?.positions && v?.positions?.length > 0) {
            v.positions.forEach((signature) => {
              if (signature.required && !signature.data) {
                findSignature.push(signature);
              }
            });
          }
        }

        if (v?.stamp_positions && v?.stamp_positions?.length > 0) {
          v.stamp_positions.forEach((stamp) => {
            if (
              stamp.req &&
              Object.keys(stamp.stampFiles).length === 0
            ) {
              findStamp.push(stamp);
            }
          });
        }
        if (v?.form_attachments && v.form_attachments?.length > 0) {
          v.form_attachments.forEach((attach) => {
            if (attach.required && attach.files.length === 0) {
              findAttach.push(attach);
            }
          });
        }
        if (
          v?.form_checkbox_positions &&
          v.form_checkbox_positions?.length > 0
        ) {
          v.form_checkbox_positions.forEach((checkbox) => {
            if (checkbox.required) {
              if (checkbox.options.widget_type === 'checkbox') {
                findCheckbox.push(checkbox);
              } else {
                findRadio.push(checkbox);
              }
            }
          });
        }
        if (
          v?.form_text_positions &&
          v.form_text_positions?.length > 0
        ) {
          v.form_text_positions.forEach((text) => {
            const type = text.options.widget_type;
            if (text.required && !text.data) {
              if (type === 'text') {
                errorMessage[type] = 'UPLOAD.Please enter text';
              } else if (type === 'number') {
                errorMessage[type] = 'UPLOAD.Please enter number';
              } else if (type === 'dropdown') {
                errorMessage[type] =
                  'UPLOAD.Please specify dropdown option';
              } else if (type === 'title') {
                errorMessage[type] = 'UPLOAD.Please select title';
              } else if (type === 'company') {
                errorMessage[type] = 'UPLOAD.Please enter company';
              }
            }
            if (text.data instanceof Error) {
              errorMessage[type] = text.data.message;
            }
            if (
              type === 'number' &&
              (text.data * 1 > text.max * 1 ||
                text.data * 1 < text.min * 1)
            ) {
              errorMessage[type] =
                'UPLOAD.Please enter the number value correctly';
            }

            if (
              type === 'email' &&
              !this.emailPattern.test(
                text.data.replace(/\r?\n|\r/g, ''),
              )
            ) {
              errorMessage[type] = 'UPLOAD.Invalid email format';
            }
          });
        }
      }
    });

    const checkboxGroup = _.groupBy(findCheckbox, 'chk_group_id');
    const totalGroup = _.sortedUniqBy(findCheckbox, 'chk_group_id');
    const checkBoxData = [];
    totalGroup.forEach((checkbox) => {
      checkBoxData.push(
        checkboxGroup[checkbox.chk_group_id].some((v) => v.data),
      );
    });

    const radioGroup = _.groupBy(findRadio, 'chk_group_id');
    const totalRadioGroup = _.sortedUniqBy(findRadio, 'chk_group_id');
    const radioData = [];
    totalRadioGroup.forEach((checkbox) => {
      radioData.push(
        radioGroup[checkbox.chk_group_id].some((v) => v.data),
      );
    });
    const totalError = [];

    if (findStamp.length > 0) {
      totalError.push(
        this.translate.instant('UPLOAD.Please upload stamp'),
      );
    }

    if (findSignature.length > 0) {
      totalError.push(
        this.translate.instant('UPLOAD.Please upload signature'),
      );
    }

    if (findAttach.length > 0) {
      totalError.push(
        this.translate.instant(
          'UPLOAD.Please upload attachment file',
        ),
      );
    }
    if (errorMessage) {
      Object.values(errorMessage).forEach((error: string) => {
        totalError.push(this.translate.instant(error));
      });
    }

    if (!this.drawingService.passedRequired(memo)) {
      totalError.push(
        this.translate.instant('UPLOAD.Please create a drawing'),
      );
    }

    if (checkBoxData.some((v) => !v)) {
      totalError.push(
        this.translate.instant('UPLOAD.Please check the checkbox'),
      );
    }

    if (radioData.some((v) => !v)) {
      totalError.push(
        this.translate.instant('UPLOAD.Please check the radio'),
      );
    }
    const finalMessage = _.union(totalError);
    return finalMessage;
  }

  convertPreviewSignature(memo, currentMemberInfo) {
    const memberIsApproved = [];
    memo.table_data.levels.forEach((level) => {
      level.members.forEach((member) => {
        if (member.date_of_approve) {
          member.is_approve = true;
          memberIsApproved.push(member);
        } else {
          member.is_approve = false;
          memberIsApproved.push(member);
        }
      });
    });

    const previewSignature = [];
    const memoType = memo.upload ? memo.upload : memo.broadcast;
    memberIsApproved.forEach((member) => {
      memoType.signature_position.forEach((value) => {
        if (member.fe_mem_id === value.fe_mem_id) {
          const fe_mem_id = value.fe_mem_id;
          if (
            (fe_mem_id != null &&
              currentMemberInfo?.fe_mem_id == fe_mem_id) ||
            (fe_mem_id == null && memo.current_level === value.level)
          ) {
            value.can_edit_widget = true;
            if (memo?.status === 'approved' || member.is_approve) {
              delete value.positions;
              delete value.drawing_positions;
              delete value.stamp_positions;
              delete value.form_text_positions;
              delete value.form_checkbox_positions;
              delete value.approved_date_positions;
            }
            if (value.approved_date_positions) {
              previewSignature.push(value);
            } else {
              previewSignature.push(value);
            }
          } else {
            if (memo?.status === 'approved' || member.is_approve) {
              delete value.approved_date_positions;
              delete value.positions;
              delete value.drawing_positions;
              delete value.stamp_positions;
              delete value.form_text_positions;
              delete value.form_checkbox_positions;
            }
            value.can_edit_widget = false;
            previewSignature.push(value);
          }
        }
      });
    });

    return previewSignature;
  }

  calculateWidgetWidth(
    markerIdentity: MarkerIdentity,
    fontSizePx: number,
    scale: number,
  ): number {
    const checkIsAllCapital = markerIdentity.data
      ? this.areAllLettersCapital(markerIdentity.data)
      : true;

    const getMaxLineLength = (multilineString: string): number => {
      if (!multilineString) {
        return 0;
      }
      const lines = multilineString.split('\n');
      return lines.reduce(
        (max, line) => Math.max(max, line.length),
        0,
      );
    };

    const dataLength = Math.max(
      getMaxLineLength(markerIdentity.data),
      15,
    );
    let textLengthFactor = 1 / (1 + dataLength) + 0.125;
    textLengthFactor = dataLength / 800 + textLengthFactor;

    let lowerFontSizeFactor = 1 / (1 + fontSizePx) + 0.12;
    lowerFontSizeFactor = fontSizePx / 500 + lowerFontSizeFactor;

    let upperFontSizeFactor = 1 / (1 + fontSizePx) + 0.165;
    upperFontSizeFactor = fontSizePx / 300 + upperFontSizeFactor;

    const fontSizeFactor = checkIsAllCapital
      ? upperFontSizeFactor
      : lowerFontSizeFactor;
    const calculatedScale = textLengthFactor + fontSizeFactor;

    const minWidth =
      15 *
      fontSizePx *
      (15 / 800 + (1 / (1 + 15) + 0.125) + upperFontSizeFactor);
    const width = dataLength * fontSizePx * calculatedScale;

    return width < minWidth ? minWidth : width;
  }

  areAllLettersCapital(str) {
    if (!str) {
      return false;
    }
    return str === str.toUpperCase();
  }

  changeDataMultiPages(
    markerIdentity: MarkerIdentity,
    marker,
    optionalType: string,
    applyFontSize = true,
    checkOnlyPage?,
  ) {
    marker[optionalType].forEach((position) => {
      if (markerIdentity.fe_form_id === position.fe_form_id) {
        position.data = markerIdentity.data;
        if (optionalType === 'form_text_positions') {
          position.textOverflow = markerIdentity.textOverflow;
        }
        if (optionalType === 'positions') {
          position.string = markerIdentity.string;
        }
        if (applyFontSize) {
          if (optionalType === 'form_attachments') {
            if (!checkOnlyPage) {
              position.fontSize = markerIdentity.fontSize;
            }
          } else {
            position.fontSize = markerIdentity.fontSize;
          }
        }
      }
    });
  }

  /** Convert a sample to the percentage of original PDF height */
  convertToRelativeHeight(
    sampleHeight: number,
    pdfElementHeight: number,
  ): number {
    return sampleHeight / pdfElementHeight;
  }

  /** Convert a sample to the percentage of original PDF width */
  convertToRelativeWidth(
    sampleWidth: number,
    pdfElementWidth: number,
  ): number {
    return sampleWidth / pdfElementWidth;
  }

  onResetSize(
    markerIdentity: MarkerIdentity,
    pdfElementHeight: number,
    pdfElementWidth: number,
    heightScale: number,
    widthScale: number,
  ): void {
    if (!markerIdentity.reset_size) {
      return;
    }
    const height = markerIdentity.reset_size.height;
    const width = markerIdentity.reset_size.width;
    markerIdentity.height = this.convertToRelativeHeight(
      height,
      pdfElementHeight,
    );
    markerIdentity.width = this.convertToRelativeWidth(
      width,
      pdfElementWidth,
    );
    markerIdentity.H = height / heightScale;
    const cloneWidget = _.cloneDeep(markerIdentity);
    if (markerIdentity.options.widget_type === 'watermark') {
      if (width === cloneWidget.W) {
        markerIdentity.H = undefined;
        markerIdentity.height = undefined;
      }
    }
    markerIdentity.W = width / widthScale;
    markerIdentity.resized = false;
    this.resetNumberSubject.next(markerIdentity);
  }

  onResetData(markerIdentity) {
    this.setResetData.next(markerIdentity);
  }

  onCheckResetSettings(isReset) {
    this.checkResetSettings.next(isReset);
  }

  onPlaceholder(markerIdentity): string {
    let result = '';
    switch (markerIdentity.text_subtype) {
      case 1:
        result = this.translate.instant('UPLOAD.Number');
        switch (markerIdentity.number_pattern) {
          case 1:
            result = `${result} (1000)`;
            return result;
          case 2:
            result = `${result} (1,000.12)`;
            return result;
          case 3:
            result = `${result} (10.12%)`;
            return result;
        }
        break;

      case 2:
        result = this.translate.instant('UPLOAD.Currency');
        switch (markerIdentity.number_pattern) {
          case 1:
            result = `${result} ($1000)`;
            return result;
          case 2:
            result = `${result} ($1,000.12)`;
            return result;
          case 3:
            result = `${result} (1000 USD)`;
            return result;
          case 4:
            result = `${result} (1,000.12 USD)`;
            return result;
          case 5:
            result = `${result} (฿1000)`;
            return result;
          case 6:
            result = `${result} (฿1,000.12)`;
            return result;
          case 7:
            result = `${result} (1000 THB)`;
            return result;
          case 8:
            result = `${result} (1,000.12 THB)`;
            return result;
        }
        break;

      case 3:
        result = this.translate.instant('UPLOAD.Phone Number');
        switch (markerIdentity.number_pattern) {
          case 1:
            result = `${result} (0812345678)`;
            return result;
          case 2:
            result = `${result} (081-234-5678)`;
            return result;
          case 3:
            result = `${result} (+XXX)`;
            return result;
        }
        break;
    }
  }

  calculatePlaceholderWidth(
    placeholder: string,
    fontSizePx: number,
    scale: number,
  ) {
    const getMaxLineLength = (multilineString: string): number => {
      if (!multilineString) {
        return 0;
      }
      const lines = multilineString.split('\n');
      return lines.reduce(
        (max, line) => Math.max(max, line.length),
        0,
      );
    };

    const dataLength = Math.max(getMaxLineLength(placeholder), 15);

    let textLengthFactor = 1 / (1 + dataLength) + 0.123;
    textLengthFactor = dataLength / 800 + textLengthFactor;

    let fontSizeFactor = 1 / (1 + fontSizePx) + 0.12;
    fontSizeFactor = fontSizePx / 500 + fontSizeFactor;

    const calculatedScale = textLengthFactor + fontSizeFactor;

    const widgetWidth = dataLength * fontSizePx * calculatedScale;
    const minWidth =
      15 *
      fontSizePx *
      (15 / 800 +
        (1 / (1 + 15) + 0.125) +
        fontSizePx / 300 +
        (1 / (1 + fontSizePx) + 0.165));
    return widgetWidth < minWidth ? minWidth : widgetWidth;
  }

  convertExpiryDateForLevel(document, tableInputDate) {
    const expiryDateForLevelLoa = [];
    if (document.expire_date_settings?.days_for_level) {
      let countDate = document.expire_date_settings.days_for_level;

      tableInputDate.forEach((level) => {
        level.members.forEach((member) => {
          const newDate = new Date();
          newDate.setDate(newDate.getDate() + countDate);
          const date = {
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            day: newDate.getDate(),
          };
          const convertDate = this.dateFormat.format(date, true);
          expiryDateForLevelLoa.push({
            level: level.level,
            full_name: member.full_name,
            expiry_date: convertDate,
            full_name_th: member.full_name_th,
          });
          countDate += document.expire_date_settings.days_for_level;
        });
      });
    }
    return expiryDateForLevelLoa;
  }

  onChangeQuery(): void {
    this.checkQueryChange.next();
  }

  onChangeCreateFolder(): void {
    this.checkCreteFolder.next();
  }

  onChangeSignatureReq(mark): void {
    this.signatureReq.next(mark);
  }

  uploadSignatureWidget(): void {
    this.signatureUpload.next();
  }

  setAttachmentFileNameBeforeSubmit(allFileUpload: any[]) {
    // Group files by fe_attach_id
    const groupedFiles: any = allFileUpload.reduce((acc, file) => {
      const key = file.fe_attach_id;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(file);
      return acc;
    }, {});

    let result = [];
    // Process each group to make items distinct by file_id and add file_name
    for (const fe_attach_id in groupedFiles) {
      // Create a Set to track unique file_ids
      const fileIdsSeen = new Set();
      const group: any = groupedFiles[fe_attach_id];

      // Filter out non-unique files and add file_name
      const filter_file = group
        .filter((file) => {
          const isUnique = !fileIdsSeen.has(file.file_id);
          fileIdsSeen.add(file.file_id);
          return isUnique;
        })
        .map((file, index) => {
          file.file_name = `attach_file_${file.fe_attach_id}_${
            index + 1
          }`;
          return file;
        });

      result = result.concat(filter_file);
    }
    return result;
  }

  getFontSizePx(size: number, pdfElementHeight): number {
    return Math.round((size / 100) * pdfElementHeight);
  }

  onApproved() {
    this.approvedSubject.next(true);
  }

  onSidebar(event) {
    this.sidebar.next(event);
  }

  changeNoneName(event) {
    this.noneUserName.next(event);
  }

  changeNoneEmail(event) {
    this.noneUserEmail.next(event);
  }

  handleNumberError(previewSignature) {
    let listErrorNumber = [];
    previewSignature.forEach((res) => {
      if (
        res?.form_text_positions &&
        res?.form_text_positions?.length > 0
      ) {
        res.form_text_positions.forEach((text) => {
          if (text?.options?.widget_type === 'number') {
            text = this.checkNumberError(text);
            if (text?.isValidNumber) {
              if (text.text_subtype === 1) {
                listErrorNumber.push(
                  this.translate.instant(
                    'UPLOAD.Invalid number format',
                  ),
                );
              }
              if (text.text_subtype === 2) {
                listErrorNumber.push(
                  this.translate.instant(
                    'UPLOAD.Invalid currency format',
                  ),
                );
              }
              if (text.text_subtype === 3) {
                listErrorNumber.push(
                  this.translate.instant(
                    'UPLOAD.Invalid phone number format',
                  ),
                );
              }
            }
          }
        });
      }
    });
    listErrorNumber = _.uniq(listErrorNumber);
    return listErrorNumber;
  }

  checkNumberError(markerIdentity: MarkerIdentity) {
    let isValidNumber = false;
    // const regex = /\d+(?:[,. ]\d+)*/;
    // let m;
    markerIdentity.isValidNumber = false;
    if (
      markerIdentity?.data === undefined ||
      markerIdentity?.data === null
    ) {
      return;
    }
    const formattedNumber = markerIdentity.data;
    if (markerIdentity.text_subtype !== 3) {
      const checkDot = [];
      [...formattedNumber].forEach((c) => {
        if (c === '.') {
          checkDot.push(c);
        }
      });
      isValidNumber = checkDot.length <= 1;
    } else {
      const checkDot = [];
      [...formattedNumber].forEach((c) => {
        if (c === '.') {
          checkDot.push(c);
        }
      });
      isValidNumber = checkDot.length === 0;
    }
    markerIdentity.isValidNumber = !isValidNumber;
    return markerIdentity;
  }
}

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  createNanoId(digits = 12): string {
    return nanoid(digits);
  }
}
