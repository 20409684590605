import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DownloadInfo,
  DownloadInfoAttachment,
  DownloadWithAttachmentsPayload,
} from 'src/app/modules/memos/model/memo.model';
import { MemoService } from '../../../modules/memos/service/memo.service';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { cloneDeep } from 'lodash';

interface DownloadInfoAttachmentWithState
  extends DownloadInfoAttachment {
  selected?: boolean;
}

interface DownloadInfoWithState extends DownloadInfo {
  selected?: boolean;
  attachments: DownloadInfoAttachmentWithState[];
  multi_pdf_uploads?: any;
}

export interface OpenDownloadModalOptions {
  parentMemoId?: number;
  disableDocument?: boolean;
  disableCert?: boolean;
  showRespondentCSV?: boolean;
}

@Component({
  selector: 'app-download-with-attachments-modal',
  templateUrl: './download-with-attachments-modal.component.html',
  styleUrls: ['./download-with-attachments-modal.component.scss'],
})
export class DownloadWithAttachmentsModalComponent {
  @ViewChild('downloadModal', { static: true })
  downloadModal: ElementRef;

  @Output() download =
    new EventEmitter<DownloadWithAttachmentsPayload>();

  themeList: ThemeList;

  options: OpenDownloadModalOptions;
  memoIds: number[] = [];
  downloadInfos: DownloadInfoWithState[] = [];
  payload: DownloadWithAttachmentsPayload;

  selectedAll: boolean;
  selectedAllAttachments: boolean;
  attachmentsCollapsed: boolean;

  hoverBackgroundColor = '#EEEEEE';

  selectedAllFile: boolean;
  multiFileCollapsed: boolean;
  isLoading = false;

  constructor(
    public modalService: NgbModal,
    private themeService: ThemeService,
    private memoService: MemoService,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  open(memoIds: number[], options?: OpenDownloadModalOptions): void {
    this.options = options;
    this.memoIds = memoIds;

    this.selectedAll = false;
    this.selectedAllAttachments = false;
    this.attachmentsCollapsed = false;
    this.initPayload();

    this.loadDownloadInfo().then(() => {
      this.modalService.open(this.downloadModal, {
        backdrop: 'static',
        centered: true,
        size: 'lg',
        keyboard: false,
        windowClass: 'custom-modal-dialog',
      });
    });
  }

  initPayload(): void {
    this.payload = {
      memo_list: this.memoIds,
      include_documents: false,
      include_certs: false,
      attachments: [],
      multi_pdf_uploads: [],
    };

    if (this.options?.showRespondentCSV) {
      this.payload.include_respondents = false;
    } else if (this.payload.include_respondents != null) {
      delete this.payload.include_respondents;
    }
  }

  loadDownloadInfo(): Promise<void> {
    let params;
    if (this.options?.parentMemoId) {
      params = { memo_list: this.options.parentMemoId };
    } else {
      params = this.memoIds
        ? { memo_list: this.memoIds.join(',') }
        : {};
    }
    this.isLoading = true;
    return new Promise((resolve, reject) => {
      this.memoService.getDownloadInfo(params).subscribe((res) => {
        this.downloadInfos = res.map((obj) => ({
          ...obj,
          selected: false,
          attachments: obj.attachments.map((attachment) => ({
            ...attachment,
            selected: false,
          })),
          multi_pdf_uploads: obj.multi_pdf_uploads.map((file) => ({
            ...file,
            selected: false,
          })),
        }));
        setTimeout(() => {
          this.isLoading = false;
        }, 100);
        resolve();
      }, reject);
    });
  }

  selectAll(): void {
    this.selectedAll = !this.selectedAll;
    if (!this.options?.disableDocument) {
      this.payload.include_documents = this.selectedAll;
    }
    if (!this.options?.disableCert) {
      this.payload.include_certs = this.selectedAll;
    }
    if (this.options?.showRespondentCSV) {
      this.payload.include_respondents = this.selectedAll;
    }

    if (this.downloadInfos.length > 0) {
      this.selectAllAttachments(this.selectedAll);
    }
  }

  selectAllAttachments(selected?: boolean): void {
    this.selectedAllAttachments =
      selected != null ? selected : !this.selectedAllAttachments;

    this.downloadInfos.forEach((obj, index) => {
      this.selectAllAttachmentsInMemo(
        index,
        this.selectedAllAttachments,
      );
    });
  }

  selectAllMultiFile(selected?: boolean): void {
    this.selectedAllFile =
      selected != null ? selected : !this.selectedAllFile;

    this.downloadInfos.forEach((obj, index) => {
      this.selectAllMultiFileInMemo(index, this.selectedAllFile);
    });
  }

  selectAllAttachmentsInMemo(
    index: number,
    selected?: boolean,
  ): void {
    if (selected == null) {
      // toggle
      selected = !this.downloadInfos[index].selected;
    }

    this.downloadInfos[index].selected = selected;
    this.downloadInfos[index].attachments.forEach(
      (obj, attachmentIndex) => {
        this.selectAttachment(index, attachmentIndex, selected);
      },
    );
  }

  selectAllMultiFileInMemo(index: number, selected?: boolean): void {
    if (selected == null) {
      // toggle
      selected = !this.downloadInfos[index].selected;
    }

    this.downloadInfos[index].selected = selected;
    this.downloadInfos[index].multi_pdf_uploads.forEach(
      (obj, fileIndex) => {
        this.selectMultiFile(index, fileIndex, selected);
      },
    );
  }

  selectAttachment(
    memoIndex: number,
    attachmentIndex: number,
    selected?: boolean,
  ): void {
    const attachments = this.downloadInfos[memoIndex].attachments;

    if (selected == null) {
      // toggle
      selected = !attachments[attachmentIndex].selected;
    }

    attachments[attachmentIndex].selected = selected;
  }

  selectMultiFile(
    memoIndex: number,
    fileIndex: number,
    selected?: boolean,
  ): void {
    const file = this.downloadInfos[memoIndex].multi_pdf_uploads;

    if (selected == null) {
      // toggle
      selected = !file[fileIndex].selected;
    }

    file[fileIndex].selected = selected;
  }

  close(): void {
    this.modalService.dismissAll();
  }

  onConfirmClick(): void {
    const payload: DownloadWithAttachmentsPayload = cloneDeep(
      this.payload,
    );
    payload.attachments = this.selectedAttachmentIds;
    payload.multi_pdf_uploads = this.selectedFileIds;

    this.download.emit(payload);
    this.close();
  }

  toggleCollapse(): void {
    this.attachmentsCollapsed = !this.attachmentsCollapsed;
  }

  toggleCollapseMultiFile(): void {
    this.multiFileCollapsed = !this.multiFileCollapsed;
  }

  get selectedAttachmentIds(): number[] {
    return this.downloadInfos
      .map((obj) =>
        obj.attachments
          .filter((attachment) => attachment.selected)
          .map((attachment) => attachment.id),
      )
      .reduce((a, b) => a.concat(b), []);
  }

  get selectedFileIds(): number[] {
    return this.downloadInfos
      .map((obj) =>
        obj.multi_pdf_uploads
          .filter((file) => file.selected)
          .map((file) => file.id),
      )
      .reduce((a, b) => a.concat(b), []);
  }

  get canSubmit(): boolean {
    return (
      this.payload?.include_documents ||
      this.payload?.include_certs ||
      this.payload?.include_respondents ||
      this.selectedAttachmentIds?.length > 0 ||
      this.selectedFileIds?.length > 0
    );
  }
}
