import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../shared/models/user.models';
import { ApiService } from '../http/api.service';

import { API_URL } from 'src/app/shared/service/api.constant';
import { UserDetail } from 'src/app/store/auth/auth.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<User>;

  constructor(private http: ApiService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject(
      this.currentUserValue,
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): string {
    return localStorage.getItem('currentUser');
  }

  public get isSetPassword(): string {
    return localStorage.getItem('is_set_password');
  }

  public get isSetTc(): string {
    return localStorage.getItem('is_pass_t_c');
  }

  public get isSetOtp(): string {
    return localStorage.getItem('otp_type');
  }

  public get httpHeader() {
    if (
      localStorage.getItem('JWTKey') &&
      this.router.url.includes('/approval/')
    ) {
      return { Authorization: localStorage.getItem('JWTKey') };
    }
    return {
      Authorization: `Bearer ${localStorage.getItem('currentUser')}`,
    };
  }

  login(username: string, password: string): Observable<User> {
    return this.http
      .post<User>('/api/token-auth/', {
        username,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(
            'currentUser',
            JSON.stringify(user.access),
          );
          this.currentUserSubject.next(user);
          return user;
        }),
      );
  }

  loginSSo(code: string, type: string): Observable<User> {
    return this.http
      .post<User>('/api/eko-auth/', {
        code,
        type,
      })
      .pipe(
        map((user) => {
          localStorage.setItem(
            'currentUser',
            JSON.stringify(user.access),
          );
          this.currentUserSubject.next(user);
          return user;
        }),
      );
  }

  logout() {
    return this.http.post('/api/logout/', {
      refresh: localStorage.getItem('refresh'),
    });
  }

  getPersonDetail(id: number): Observable<UserDetail> {
    return this.http.get<UserDetail>(API_URL.people + id + '/');
  }

  forgotPassword(email: { email: string }): Observable<any> {
    return this.http.post(API_URL.reset_password, email);
  }

  setUserDetail(data?): void {
    localStorage.setItem('currentUser', data.access);
    if ('refresh' in data) {
      localStorage.setItem('refresh', data.refresh);
    }
    localStorage.setItem('username', data.username);
    localStorage.setItem('photoUrl', data.photo || '');
    localStorage.setItem('name', data.first_name);
    localStorage.setItem(
      'full_name',
      data.first_name + ' ' + data.last_name || '',
    );
    localStorage.setItem('role', JSON.parse(data.role));
    localStorage.setItem('user_id', data.id);
    localStorage.setItem('person_id', data.person_id);
    localStorage.setItem('no_signature', data.no_signature);
    localStorage.setItem('company_admin_id', data.company_admin_id);
    localStorage.setItem('job_position', data.job_position);
    const defaultLang = localStorage.getItem('lang') || 'en';
    localStorage.setItem('lang', defaultLang);

    if (
      data.is_set_password === false ||
      data.is_pass_t_c === false ||
      data.is_password_expired
    ) {
      this.router.navigate(['/activate-account']);
    } else if (
      !data.otp_type &&
      (data.role === 'General' ||
        data.role === 'Broadcast' ||
        data.role === 'Company Admin')
    ) {
      this.router.navigate(['/activate-account']);
    }
  }

  clearStorage(): void {
    const keysToKeep = ['bannerClosed', 'resetBannerTime'];

    for (const key in localStorage) {
      if (
        localStorage.hasOwnProperty(key) &&
        !keysToKeep.includes(key)
      ) {
        localStorage.removeItem(key);
      }
    }
    localStorage.setItem('lang', 'en');
  }

  initialData(data) {
    return this.http.patch(API_URL.initial, data);
  }

  validateResetPasswordToken(data) {
    return this.http.post(API_URL.reset_password_validate, data);
  }

  resetPassword(data) {
    return this.http.post(API_URL.reset_password_confirm, data);
  }

  getPasswordPolicy() {
    return this.http.get('/api/pw-policy/');
  }

  setPassword(data) {
    return this.http.put(`/api/change-password/`, data);
  }
}
