import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { BannerService } from 'src/app/modules/setting/shared/banner.service';
import { ThemeList, ThemeService } from '../../service/theme.service';
import {
  NgbCarousel,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { BannerDetail } from 'src/app/modules/setting/shared/banner.model';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-carousel-banner',
  templateUrl: './carousel-banner.component.html',
  styleUrls: ['./carousel-banner.component.scss'],
})
export class CarouselBannerComponent implements OnInit {
  themeList: ThemeList;
  bannerList = [];
  bannerListPreview = [];
  modal: NgbModalRef;
  showBanner = false;
  currentPage = 0;
  onMobile: boolean;
  hasUrlLink: boolean;
  checkCloseBanner = false;
  initialized = false;
  @Input() isDeviceMobileLandscape = false;

  @ViewChild('carousel', { static: false }) carousel: NgbCarousel;
  @Input() isPreview: boolean;
  @Input() status: string;
  @Output() closeModal = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private alert: AlertService,
    private themeService: ThemeService,
    private bannerService: BannerService,
    private modalService: NgbModal,
    private elRef: ElementRef,
    private deviceService: DeviceDetectorService,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.elRef.nativeElement.contains(
      event.target,
    );
    if (!this.initialized) {
      return; // Skip if the component is not initialized
    }
    if (!clickedInside) {
      this.close();
    }
  }

  ngOnInit(): void {
    setTimeout(() => (this.initialized = true));
    const bannerClosed = localStorage.getItem('bannerClosed');
    const resetBannerTime = localStorage.getItem('resetBannerTime');
    const resetTime = new Date(Number(resetBannerTime) || 0);
    const currentTIme = new Date();

    if (currentTIme > resetTime) {
      localStorage.removeItem('bannerClosed');
      localStorage.removeItem('resetBannerTime');
    }

    if (bannerClosed && !this.isPreview) {
      this.close();
      this.showBanner = false;
    }
    this.getBannerToday();

    if (window.screen.width <= 576) {
      this.onMobile = true;
    } else {
      this.onMobile = false;
    }

    if (this.isPreview) {
      this.getBannerListPreview();
    }
  }

  getBannerToday(): void {
    this.bannerService.getBannerToday().subscribe(
      (res) => {
        this.bannerList = res;
        this.showBanner = true;
        if (this.bannerList?.length === 0 && !this.isPreview) {
          this.showBanner = false;
          this.close();
        }
        this.hasUrlLink = this.bannerList[this.currentPage]?.link_url
          ? true
          : false;
      },
      (error) => {
        this.alert.error(error);
      },
    );
  }

  getBannerListPreview(): void {
    const data = {
      status: this.status,
    };
    this.bannerService.getBannerList(data).subscribe(
      (res) => {
        this.bannerListPreview = res.data;
        this.showBanner = true;
        this.hasUrlLink = this.bannerListPreview[this.currentPage]
          ?.link_url
          ? true
          : false;
      },
      (error) => {
        this.alert.error(error);
      },
    );
  }

  close(): void {
    if (this.checkCloseBanner) {
      const resetTime = new Date().setHours(24, 0, 0, 0).toString();
      localStorage.setItem('bannerClosed', 'true');
      localStorage.setItem('resetBannerTime', resetTime);
      this.showBanner = false;
      this.checkCloseBanner = false;
    }
    this.modalService.dismissAll();
    this.closeModal.emit();
    this.isPreview = false;
  }

  onSlide(event): void {
    this.currentPage = event.current;
    this.hasUrlLink = this.checkUrlLink();
  }

  checkUrlLink(): boolean {
    const bannerList = this.isPreview
      ? this.bannerListPreview
      : this.bannerList;
    const currentBanner = bannerList[this.currentPage];
    return currentBanner?.link_url ? true : false;
  }

  openDetail(): void {
    const urlLink = this.isPreview
      ? this.bannerListPreview[this.currentPage]?.link_url
      : this.bannerList[this.currentPage]?.link_url;
    window.open(urlLink);
  }

  onDisplayImage(image): any {
    return image.mobile_img && this.onMobile
      ? image.mobile_img
      : image.desktop_img || image.mobile_img;
  }

  onResize(event): void {
    const pageWidth = event.target.innerWidth;
    const pageHeight = event.target.innerHeight;
    const landscape = pageWidth > pageHeight ? true : false;
    this.isDeviceMobileLandscape =
      this.deviceService.isMobile() && landscape;
  }
}
